define("js-common/models/task-list", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    name: attr('string'),
    // For UI only not persisted
    addingTask: attr('boolean'),
    isEditing: attr('boolean'),
    isCommonEditing: attr('boolean'),
    selectAll: attr('boolean'),
    expanded: attr('boolean'),
    listTemplate: belongsTo('list-template', {
      async: true
    }),
    matter: belongsTo('matter', {
      async: true
    }),
    tasks: hasMany('task', {
      async: false
    }),
    selectTasks: Ember.observer('selectAll', function () {
      Ember.A(this.tasks).setEach('isSelected', this.selectAll);
    }),
    taskSort: ['order'],
    sortedTasks: Ember.computed.sort('tasks', 'taskSort')
  });

  _exports.default = _default;
});