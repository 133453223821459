define("@smith-carson/ui/pods/components/light-table/cells/workflow/component", ["exports", "ember-light-table/components/cells/base", "@smith-carson/ui/pods/components/light-table/cells/workflow/template"], function (_exports, _base, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
      Extends the base `ember-light-table cell` component to provide custom
      rendering for workflow status information.
  
      @module LightTable::Cells::WorkflowComponent
      @extends EmberLightTable.CellsBaseComponent
   */
  var _default = _base.default.extend({
    layout: _template.default,
    assignee: Ember.computed('column', 'row', function () {
      var column = this.column;
      var valuePath = column.valuePath;
      return this.row.get("".concat(valuePath, "A.fullName"));
    }),
    status: Ember.computed('column', 'row', function () {
      var column = this.column;
      var valuePath = column.valuePath;
      return this.row.get("".concat(valuePath, "S"));
    })
  });

  _exports.default = _default;
});