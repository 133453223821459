define("js-common/models/matter-has-user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    modelName: 'matter-has-user',
    // before we were using constructor.modelName but is no longer reliable
    bill: attr('boolean', {
      defaultValue: false
    }),
    billCopy: attr('boolean', {
      defaultValue: false
    }),
    report: attr('boolean', {
      defaultValue: false
    }),
    referredBy: attr('boolean', {
      defaultValue: false
    }),
    whoHired: attr('boolean', {
      defaultValue: false
    }),
    decisionMaker: attr('boolean', {
      defaultValue: false
    }),
    rate: attr('string'),
    contact: attr('string'),
    userType: attr('string'),
    isOrigin: attr('boolean', {
      defaultValue: false
    }),
    // relationships
    matter: belongsTo('matter', {
      async: false
    }),
    user: belongsTo('user', {
      async: true
    }),
    employee: belongsTo('employee', {
      async: true
    }),
    group: belongsTo('group', {
      async: true
    }),
    // field to show the source of the permission
    source: attr('string'),
    primary: Ember.computed('contact', function () {
      return this.contact === 'Primary';
    }),
    sourceName: Ember.computed('source', function () {
      switch (this.source) {
        case 'matter_has_users':
          return 'Given to User';

        case 'matter_has_teams':
          return 'Given to Team';
      }

      return '';
    })
  });

  _exports.default = _default;
});