define("@smith-carson/ui/pods/components/light-table/cells/status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KoYBciIW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"status \",[24,0,[\"statusClassName\"]]]]],[11,\"data-test-component\",\"light-table-cell-status\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"d-flex align-items-center\"],[9],[0,\"\\n        \"],[1,[29,\"html-safe\",[[24,0,[\"statusLabel\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,0,[\"infoContent\"]]],null,{\"statements\":[[0,\"            \"],[5,\"sc-icon\",[[13,\"class\",\"info-icon\"],[13,\"data-test-icon\",\"info\"]],[[\"@icon\"],[\"info\"]],{\"statements\":[[0,\"\\n                \"],[5,\"bs-tooltip\",[],[[],[]],{\"statements\":[[1,[24,0,[\"infoContent\"]],false]],\"parameters\":[]}],[0,\"\\n            \"]],\"parameters\":[]}],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"sr-only\"],[11,\"data-test-a11y-tooltip\",\"status-cell\"],[9],[0,\"\\n                \"],[1,[24,0,[\"infoContent\"]],false],[0,\"\\n            \"],[10]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@smith-carson/ui/pods/components/light-table/cells/status/template.hbs"
    }
  });

  _exports.default = _default;
});